#LegalWrapper {
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  a {
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    color: var(--power-automate-color);
    transition: color 0.1s ease-in-out;
    &:hover {
      color: var(--power-apps-color);
    }
  }

  ul,
  li {
    padding-left: 1rem;
    list-style-type: disc;
  }
}
