// @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&family=Montserrat:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button,
  .button {
    @apply p-4 m-4 text-3xl rounded-2xl bg-powerapps text-almostwhite transition-colors;
  }

  h1 {
    @apply text-4xl mt-6 mb-2;
  }
  h2 {
    @apply text-3xl mt-6 mb-2;
  }
  h3 {
    @apply text-2xl mt-6 mb-2;
  }
  h4 {
    @apply text-xl mt-6 mb-2;
  }
}

:root {
  --bg-color: #262626;
  --text-color: #eeeeee;
  --power-apps-color: #d872bc;
  --power-automate-color: #3181f0;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  min-width: 100%;
  min-height: 100%;
}

body {
  font-family: 'Manrope', sans-serif;

  background-color: var(--bg-color);
  color: var(--text-color);

  *::selection {
    background-color: var(--power-apps-color);
  }
}

html {
  font-size: 100%;
  @media screen and (max-width: 1536px) {
    font-size: 90%;
  }
  @media screen and (max-width: 1280px) {
    font-size: 80%;
  }
  @media screen and (max-width: 1024px) {
    font-size: 70%;
  }
  @media screen and (max-width: 768px) {
    font-size: 60%;
  }
  @media screen and (max-width: 640px) {
    font-size: 50%;
  }
}

/* ----------------- */

.title {
  font-size: 6rem;
  font-weight: 400;
  margin: 1rem 0 1rem 0;
  letter-spacing: 0.3rem;
  line-height: 1.3;
}
.subTitle {
  font-size: 2rem;
  font-weight: 200;
  margin: 1rem 0 1rem 0;
  padding-right: 2rem;
  line-height: 1.4;
  letter-spacing: 0.15rem;
}

.Toastify {
  font-size: 1.6rem;
}

.rainbowButton {
  background: linear-gradient(to bottom right, #6889ff 0%, #c668ff 100%);
  border: 1px solid #eeeeee71;
  position: relative;
  z-index: 1;

  transition: 1s box-shadow, 1s background-color;
  box-shadow: 3px 3px 16px 0px rgba(68, 68, 68, 0.67);
  &:hover {
    box-shadow: -3px -3px 16px 0px rgba(68, 68, 68, 0.67);
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    border-radius: 1rem;
    background: linear-gradient(to top left, #6889ff 0%, #c668ff 100%);
    z-index: -1;
    transition: opacity 0.5s linear;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
}

.rainbowBackground {
  display: block;
  overflow: auto;
  min-height: 100%;
  min-height: 100%;

  box-sizing: border-box;
  padding: 4rem 12rem 4rem 12rem;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  background: linear-gradient(to bottom right, #466effb0 0%, #b63fff 100%);

  @media screen and (max-width: 1280px) {
    padding: 3rem 8rem 3rem 8rem;
  }
  @media screen and (max-width: 768px) {
    // text-align: center;
    padding: 2rem 4rem 2rem 4rem;
  }
}
