#ArticleWrapper {
  @media screen and (max-width: 1280px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

#ArticleContent {
  a {
    color: var(--power-apps-color);
    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 1.2rem;
    margin-bottom: 0.7rem;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  ul li {
    margin-left: 2rem;
    &::before {
      content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #b63fff; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      font-size: 1.3em; /* Change the size of the bullet */
    }

    li {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      // Add square bullets to 2nd level
      &::before {
        content: '\25A0';
        font-size: 0.8em;
        position: relative;
        bottom: 0.1rem;
      }
    }
  }

  ol {
    // Add counter to list and correct spacing
    counter-reset: list-counter;
    li {
      margin-top: 1rem;
      margin-bottom: 1rem;
      li {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;

        &::before {
          width: 2.6rem;
        }
        li {
          &::before {
            width: 3.9rem;
          }
          li {
            &::before {
              width: 5.2rem;
            }
          }
        }
      }

      p {
        display: inline;
      }
      margin-left: 2rem;
      &::before {
        content: counters(list-counter, '.') '. ';
        counter-increment: list-counter;
        color: #b63fff;
        display: inline-block;
        width: 100%;
        width: 1.3rem;
        margin-left: -2rem;
        font-size: 1.2rem;
      }
    }
  }

  code {
    background: #ffeff0;
    word-wrap: break-word;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    padding: 0.3rem;
    white-space: pre-wrap;
    word-break: break-all;

    &.block {
      display: block;
      padding: 1.3rem;
      width: 100%;
    }
  }
  table {
    margin: 1rem;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid #ddd;
      text-align: left;
      padding: 0.5rem;
    }
  }
}
